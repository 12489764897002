<template>
  <div class="bg-white border border-[#e2e8f0] rounded-sm">
    <div class="border-muted-200 dark:border-muted-700 p-4 px-6">
      <div class="flex flex-col items-start gap-x-1 sm:flex-row">
        <div class="w-[80px] relative">
          <div class="nui-avatar-inner">
            <object :data="talent?.avatar" class="rounded-md w-[80px] h-[80px]">
              <img
                src="https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg?t=st=1738045667~exp=1738049267~hmac=f796c2f4d3c6975eee36fa000582c21e60ab7abfaf9e8696933caced9f66639b&w=740"
                class="rounded-md"
                alt="Image not found"
              />
            </object>
          </div>
          <div class="h-[1.2rem] w-[1.2rem] rounded-full overflow-hidden absolute bottom-[.5rem] z-10 bg-white p-0.5 right-[.5rem]">
            <img
              :src="
                hasApplications
                  ? 'https://img.freepik.com/premium-vector/glass-tick-green-background-green-concept_205544-1710.jpg?w=740'
                  : 'https://img.freepik.com/premium-vector/cross-scotch-tape-icon-flat-illustration-cross-scotch-tape-vector-icon-web-design_98396-31752.jpg?w=740'
              "
              class="rounded-full"
              alt=""
            />
          </div>
        </div>
        <div class="text-center leading-none sm:text-left">
          <h4 class="text-muted-800 dark:text-muted-100 font-sans text-base font-medium mb-1">{{ talent?.name }}</h4>
          <p class="text-muted-400 font-sans text-base text-[#94a3b8]">
            <span class="bg-[#DFF6FD] text-[#004085] p-1 px-2 rounded-md text-sm font-medium font-manrope"
              >{{ intNum(talent?.job_preference?.desired_salary) }}/yr</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="flex justify-between items-center px-6">
      <!-- <vue-rating v-model="talent.profile_rating" :starSize="15" starColor="#FDD836" inactiveColor="#D8D8D8" /> -->

      <div class="mt-4 flex items-center gap-3 sm:ms-auto sm:mt-0">
        <a
          :href="talent?.resume"
          rel="noopener noreferrer"
          target="_blank"
          :disabled="!talent?.resume"
          class="border border-[#e2e8f0] hover:border-blue-500 hover:text-blue-500 shadow-muted-300/30 flex w-[2rem] h-[2rem] items-center justify-center rounded-full bg-white shadow-lg transition-all duration-300"
        >
           <img src="@/assets/icons/pdf.svg" alt="">
        </a>
        <a
          :href="linkedin"
          target="_blank"
          :disabled="linkedin == ''"
          class="border border-[#e2e8f0] hover:border-blue-500 hover:text-blue-500 shadow-muted-300/30 flex w-[2rem] h-[2rem] items-center justify-center rounded-full bg-white shadow-lg transition-all duration-300"
        >
          <img src="@/assets/icons/linkedinline.svg" alt="">
        </a>
      </div>
    </div>

    <div class="grid grid-cols-2 border border-grey-1 rounded-md my-5 mx-6">
      <p class="text-center border-r border-grey-1 py-3 text-gray-500">Exp. : 0-{{ talent?.years_of_experience || 1 }} Years</p>
      <p class="text-center py-3 text-gray-500">{{ talent?.role_title || 'No role tile' }}</p>
    </div>

    <div class="px-6 text-gray-500 leading-[170%] h-[50px] overflow-hidden">
      <p>{{ talent?.bio || 'No bio information provided for this talent yet...' }}</p>
    </div>

    <div class="flex flex-col items-center justify-between px-6 py-4 sm:flex-row">
      <div class="mt-4 w-full">
        <button
          type="button"
          rel=""
          target=""
          class="bg-brand-black hover:shadow transition-all border border-brand-black text-white rounded-md py-2.5 px-3.5 w-full"
          @click="$emit('profile')"
        >
          View Profile
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import GithubIcon from '@/assets/icons/github.svg';
import FacebookIcon from '@/assets/icons/facebook.svg';
import TwitterIcon from '@/assets/icons/twitter.svg';
import EnvelopeIcon from '@/assets/icons/envelopeline.svg';
import { computed } from 'vue';
import { intNum } from '@/utils/helper';

const props = defineProps({
  talent: { type: Object },
});

const emits = defineEmits(['profile', 'mail', 'share']);

const hasApplications = computed(() => {
  return Number(props.talent?.job_applications_count) > 0 ? true : false;
});

const linkedin = computed(() => {
  const linkedin_url = props.talent?.social_links.filter((item) => item.platform == 'linkedin');
  if (!linkedin_url.length) return null;

  return linkedin_url[0]?.url;
});
</script>

<style lang="scss" scoped>
a:disabled {
  pointer-events: none !important;
  cursor: default !important;
}
</style>
